define("discourse/plugins/discourse-duplicate-notification-terminator/discourse/templates/connectors/after-topic-footer-buttons/wipe-admin-notifications-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.args.topic.isPrivateMessage}}
    {{#if this.currentUser.can_wipe_notifications}}
      {{#if this.siteSettings.duplicate_notification_admin_btn_enabled}}
        {{d-button  action=(action "wipeNotifications" this.args.topic.id)
                    class="btn-danger btn-large"
                    disabled=this.loading
                    label=(unless this.site.mobileView "duplicate_notification_terminator.wipe_notifications")
                    icon="bell-slash"}}
      {{/if}}
    {{/if}}
  {{/if}}
  */
  {
    "id": "ExT20sZ5",
    "block": "[[[41,[30,0,[\"args\",\"topic\",\"isPrivateMessage\"]],[[[41,[30,0,[\"currentUser\",\"can_wipe_notifications\"]],[[[41,[30,0,[\"siteSettings\",\"duplicate_notification_admin_btn_enabled\"]],[[[1,\"      \"],[1,[28,[35,1],null,[[\"action\",\"class\",\"disabled\",\"label\",\"icon\"],[[28,[37,2],[[30,0],\"wipeNotifications\",[30,0,[\"args\",\"topic\",\"id\"]]],null],\"btn-danger btn-large\",[30,0,[\"loading\"]],[52,[51,[30,0,[\"site\",\"mobileView\"]]],\"duplicate_notification_terminator.wipe_notifications\"],\"bell-slash\"]]]],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[],false,[\"if\",\"d-button\",\"action\",\"unless\"]]",
    "moduleName": "discourse/plugins/discourse-duplicate-notification-terminator/discourse/templates/connectors/after-topic-footer-buttons/wipe-admin-notifications-button.hbs",
    "isStrictMode": false
  });
});