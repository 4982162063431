define("discourse/plugins/discourse-duplicate-notification-terminator/discourse/initializers/duplicate-notification-terminator", ["exports", "discourse/lib/plugin-api", "discourse/routes/user-notifications"], function (_exports, _pluginApi, _userNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initWithApi(api) {
    const messageBus = api.container.lookup("message-bus:main");
    const appEvents = api.container.lookup("service:app-events");
    const eventName = "duplicate-notification-terminator";
    messageBus.subscribe("/duplicate-notification-terminator", nIds => {
      appEvents.trigger(eventName, nIds);
    });
    _userNotifications.default.reopen({
      setupController(controller, model) {
        this.appEvents.on(eventName, nIds => {
          controller.get("model").filter(x => nIds.includes(x.id)).setEach("read", true);
        });
        this._super(...arguments);
      },
      actions: {
        willTransition() {
          this.appEvents.off(eventName);
          this._super(...arguments);
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "duplicate-notification-terminator",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", initWithApi);
    }
  };
});